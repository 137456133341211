import {Link} from "react-router-dom";
import {Wallet} from "../../types/Wallet";

interface WalletLinkProps {
    wallet: Wallet
}

const WalletLink: React.FC<WalletLinkProps> = ({ wallet}) => {
    return (
        <Link to={`/wallets/${wallet.id}`}>{wallet.name}</Link>
    )
}

export default WalletLink;
