import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress, Stack, Typography, Grid
} from '@mui/material';
import WalletLink from "./WalletLink";
import {enqueueSnackbar} from "notistack";
import {Wallet} from "../../types/Wallet";
import CompanyLink from "../Company/CompanyLink";
import {Link} from "react-router-dom";
import {useApi} from "../../context/ApiProvider";

const WalletComponent: React.FC = () => {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [wallets, setWallets] = useState([]);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true)
        api?.get(`/wallets`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch wallets data!')
                }

                setWallets(response.body.results)
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch wallets data!', {variant: 'error'})
                setWallets([])
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = (wallet: Wallet) => () => {
        api?.delete(`/wallets/${wallet.id}`)
            .then(() => {
                enqueueSnackbar('Successfully deleted wallet!', {variant: 'success'})
                fetchData()
            })
            .catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar('Failed to delete wallet!', {variant: 'error'})
            })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4">Wallets</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button component={Link} variant="contained" color="success" to="/wallets/add">
                    Add Wallet
                </Button>
            </Grid>
            <Grid item xs={12}>
            {isLoading ? <LinearProgress /> : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wallets && wallets.map((row: Wallet) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>
                                        <WalletLink wallet={row} />
                                    </TableCell>
                                    <TableCell>
                                        <CompanyLink company={row.company} />
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Button component={Link}
                                                    variant="contained"
                                                    to={`/wallets/${row.id}/edit`}
                                                    color="warning"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={handleDelete(row)}
                                                color="error"
                                            >
                                                Delete
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>)}
            </Grid>
        </Grid>
    );
}

export default WalletComponent;