import {
    Button, Grid,
    LinearProgress,
    Paper, Stack,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {NotificationChannel} from "../../types/NotificationChannel";
import UpsertNotificationChannelModal from "./UpsertNotificationChannelModal";
import BooleanIcon from "../Shared/BooleanIcon";
import {enqueueSnackbar} from "notistack";
import {DistributeChain} from "../../types/DistributeChain";
import CompanyLink from "../Company/CompanyLink";
import {useApi} from "../../context/ApiProvider";

export interface NotificationChannelsListProps {
    distributeChain?: DistributeChain
}

const NotificationChannelsList: React.FC<NotificationChannelsListProps> = (
    {
        distributeChain,
    }
) => {
    const api = useApi();
    const [notificationChannels, setNotificationChannels] = useState<NotificationChannel[]>([]);
    const [editNotificationChannel, setEditNotificationChannel] = useState<NotificationChannel|null>(null);
    const [openModal, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRender = useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true)
        setNotificationChannels([])
        let url
        if (distributeChain) {
            url = `/distribute-chains/${distributeChain.id}/notification-channels`
        } else {
            url = `/notification-channels`
        }

        api?.get(url)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch!')
                }

                setNotificationChannels(response.body.results);
            })
            .catch((error) => {
                console.error('Error:', error);
                setNotificationChannels([])
            })
            .finally(() => setIsLoading(false))
        ;
    }, [setNotificationChannels, setIsLoading, api, distributeChain])

    useEffect(() => {
        if (isFirstRender.current) {
            fetchData()
            isFirstRender.current = false
        }
    }, [fetchData]);

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setEditNotificationChannel(null);
        setModalOpen(false);
    };

    const handleEdit = (id: number) => {
        const notificationChannel = notificationChannels
            .find((notificationChannel: NotificationChannel) => notificationChannel.id === id);
        if (notificationChannel === undefined) {
            enqueueSnackbar(`No notification channel found with id ${id}`, {variant: 'error'})

            return
        }

        setEditNotificationChannel(notificationChannel)
        handleModalOpen()
    };

    const handleRemove = (id: number) => {
        api?.delete(`/notification-channels/${id}`)
            .then(() => {
                fetchData()
                enqueueSnackbar(`Notification channel removed successfully`, {variant: 'success'})
            }).catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar(`Error when deleting notification channel`, {variant: 'error'});
            });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography typography={"h4"}>Notification Channels</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="success" onClick={handleModalOpen}>
                    Add Notification Channel
                </Button>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? <LinearProgress/> : (<>
                    <UpsertNotificationChannelModal
                        open={openModal}
                        fetchData={fetchData}
                        notificationChannel={editNotificationChannel}
                        handleClose={handleModalClose}
                    />
                    {isLoading ? <LinearProgress /> : <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Enabled</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notificationChannels && notificationChannels.map((notificationChannel) => (
                                    <TableRow key={notificationChannel.id}>
                                        <TableCell>{notificationChannel.id}</TableCell>
                                        <TableCell>{notificationChannel.type}</TableCell>
                                        <TableCell>{notificationChannel.name}</TableCell>
                                        <TableCell>
                                            <BooleanIcon value={notificationChannel.is_enabled} />
                                        </TableCell>
                                        <TableCell>
                                            <CompanyLink company={notificationChannel.company} />
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button variant="contained" color="warning"
                                                        onClick={() => handleEdit(notificationChannel.id ?? 0)}>
                                                    Edit
                                                </Button>
                                                <Button variant="contained" color="error"
                                                        onClick={() => handleRemove(notificationChannel.id ?? 0)}>
                                                    Remove
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </>)}
            </Grid>
        </Grid>
    )
}

export default NotificationChannelsList;
