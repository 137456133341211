import React, {useCallback, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Drawer, List } from '@mui/material';
import { Box } from '@mui/system';
import { navbarItems } from './Navbar';
import DistributeChainComponent from './components/distributeChains/DistributeChainComponent';
import DashboardComponent from './components/DashboardComponent';
import DistributeChainOverview from './components/distributeChains/DistributeChainOverview';
import UpsertDistributeChain from './components/distributeChains/UpsertDistributeChain';
import UpsertWallet from "./components/Wallet/UpsertWallet";
import WalletOverview from "./components/Wallet/WalletOverview";
import WalletComponent from "./components/Wallet/WalletComponent";
import UpsertTask from "./components/Tasks/UpsertTask";
import NotificationChannelsComponent from "./components/notificationChannels/NotificationChannelsComponent";
import LoginComponent from "./components/Security/LoginComponent";
import {LogoutComponent} from "./components/Security/LogoutComponent";
import CompaniesComponent from "./components/Company/CompaniesComponent";
import CompanyOverview from "./components/Company/CompanyOverview";
import UpsertCompany from "./components/Company/UpsertCompany";
import UserComponent from "./components/User/UserComponent";
import UserOverview from "./components/User/UserOverview";
import UpsertUser from "./components/User/UpsertUser";
import {useUserStore} from "./stores/useUserStore";
import {useApi} from "./context/ApiProvider";
import ListItem from "@mui/material/ListItem";
import UserIcon from "@mui/icons-material/AccountCircle";

function App() {
    const api = useApi();
    const { user, setUser, securityTokens, setApiSecurityTokens } = useUserStore();

    const fetchMe = useCallback(() => {
        if (!user) {
            api?.get('/users/me')
                .then((response) => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch user!')
                    }

                    setUser(response.body);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setUser(undefined);
                    setApiSecurityTokens(null);
                });
        }
    }, [user, setUser, securityTokens]);

    useEffect(() => {
        if (securityTokens) {
            fetchMe();
        } else {
            setUser(undefined);
            setApiSecurityTokens(null);
        }
    }, [fetchMe, securityTokens]);

    return (
        <>
            <Router>
                <Box sx={{ display: 'flex' }}>
                    {securityTokens && <Drawer
                        variant="permanent"
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        <List>
                            {navbarItems.map((item, index) => (
                                user && item.security(user) && <item.component key={item.name} name={item.name} icon={item.icon} path={item.path} />
                            ))}
                            <ListItem>
                                <UserIcon /> Hello, {user?.username}
                            </ListItem>
                        </List>
                    </Drawer>}
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Routes>
                            {user ? (<>
                                <Route path="/" element={<DashboardComponent />} />
                                <Route path="/distribute-chains" element={<DistributeChainComponent />} />
                                <Route path="/distribute-chains/:id" element={<DistributeChainOverview />} />
                                <Route path="/distribute-chains/:id/edit" element={<UpsertDistributeChain />} />
                                <Route path="/distribute-chains/add" element={<UpsertDistributeChain />} />
                                <Route path="/distribute-chains/:id/task/add" element={<UpsertTask />} />
                                <Route path="/distribute-chains/:id/task/:task_id/edit" element={<UpsertTask />} />
                                <Route path="/wallets" element={<WalletComponent />} />
                                <Route path="/wallets/:id" element={<WalletOverview />} />
                                <Route path="/wallets/:id/edit" element={<UpsertWallet />} />
                                <Route path="/wallets/add" element={<UpsertWallet />} />
                                <Route path="/notification-channels" element={<NotificationChannelsComponent />} />
                                <Route path="/companies" element={<CompaniesComponent />} />
                                <Route path="/companies/:id" element={<CompanyOverview />} />
                                <Route path="/companies/:id/edit" element={<UpsertCompany />} />
                                <Route path="/companies/add" element={<UpsertCompany />} />
                                <Route path="/users" element={<UserComponent />} />
                                <Route path="/users/:id" element={<UserOverview />} />
                                <Route path="/users/:id/edit" element={<UpsertUser />} />
                                <Route path="/users/add" element={<UpsertUser />} />
                                <Route path="/logout" element={<LogoutComponent />} />
                            </>) : (
                                <Route path="/*" element={<LoginComponent />} />
                            )}
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </>
    );
}

export default App;