import {Link} from "react-router-dom";
import {DistributeChain} from "../../types/DistributeChain";

interface DistributeChainLinkProps {
    distributeChain: DistributeChain
}

const DistributeChainLink: React.FC<DistributeChainLinkProps> = ({ distributeChain}) => {
    return (
        <Link to={`/distribute-chains/${distributeChain.id}`}>
            {distributeChain.name}
        </Link>
    )
}

export default DistributeChainLink;
