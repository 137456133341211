import React, {useState, useEffect, useCallback} from 'react';
import {
    Button,
    LinearProgress, Typography, Grid, Card, CardContent, CardActions
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import { DistributeChain } from '../../types/DistributeChain';
import BooleanIcon from "../Shared/BooleanIcon";
import {enqueueSnackbar} from "notistack";
import WalletLink from "../Wallet/WalletLink";
import TaskListComponent from "../Tasks/TaskListComponent";
import {eventEmitter} from "../../services/Events/EventEmitter";
import {EventsEnum} from "../../services/Events/EventsEnum";
import {TaskLogListComponent} from "../Tasks/TaskLogListComponent";
import CompanyLink from "../Company/CompanyLink";
import AdminButton from "../Shared/AdminButton";
import {useApi} from "../../context/ApiProvider";

type RouteParams = {
    [key: number]: string;
};

const DistributeChainOverview: React.FC = () => {
    const api = useApi()

    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [distributeChain, setDistributeChain] = useState<DistributeChain | null>(null);
    const firstRender = React.useRef(true);
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        setLoading(true)
        api?.get(`/distribute-chains/${id}`)
            .then((response) => {
                if (response.ok && response.body) {
                    setDistributeChain(response.body);
                    return
                }
                if (response.status === 403) {
                    navigate('/distribute-chains')
                }

                enqueueSnackbar('Failed to fetch distributeChain data!', {variant: 'error'});
                throw new Error('Failed to fetch distributeChain data!')
            })
            .catch((error) => {
                console.error(error)
                setDistributeChain(null)
            })
            .finally(() => setLoading(false))
    }, [id]);

    eventEmitter.on(EventsEnum.TASK_DELETED, () => {
        fetchData();
    });

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false
        }
    }, [fetchData, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Typography variant="h5">Distribute chain Overview</Typography>
                </Grid>
                <Grid xs={12} md={12}>
                    {isLoading ? <LinearProgress /> : (distributeChain && (
                        <>
                            <Card>
                                <CardContent>
                                    <p>Name: {distributeChain.name}</p>
                                    <p>Chain: {distributeChain.chain}</p>
                                    <p>Company: {distributeChain.company && <CompanyLink company={distributeChain.company} />}</p>
                                    <p>Fee: {distributeChain.fee}</p>
                                    <p>Gas Adjustment: {distributeChain.gas_adjustment}</p>
                                    <p>Rest URL: {distributeChain.rest_url}</p>
                                    <p>RPC URL: {distributeChain.rpc_url}</p>
                                    <p>Default Denom: {distributeChain.default_denom}</p>
                                    <p>Validator Address: {distributeChain.validator_address}</p>
                                    <p>Claim Greater Than: {distributeChain.claim_greater_than}</p>
                                    <p>Last Error: {distributeChain.last_error}</p>
                                    <p>Last Claimed At: {distributeChain.last_claimed_at?.toLocaleString()}</p>
                                    <p>Wallet: {distributeChain.wallet && <WalletLink wallet={distributeChain.wallet} />}</p>
                                    <p>Is Enabled: <BooleanIcon value={distributeChain.is_active}/></p>
                                    <p>Created At: {distributeChain.created_at?.toLocaleString()}</p>
                                    <p>Updated At: {distributeChain.updated_at?.toLocaleString()}</p>
                                </CardContent>
                                <CardActions>
                                    <AdminButton
                                        variant="contained"
                                        color="warning"
                                        label={"Edit"}
                                        href={`/distribute-chains/${distributeChain.id}/edit`}
                                        />
                                </CardActions>
                            </Card>
                        </>
                    ))}
                </Grid>
                <Grid xs={0} md={1}></Grid>
                <Grid xs={12} paddingTop={'1em'}>
                    <TaskListComponent tasks={distributeChain?.tasks || []} distributeChain={distributeChain ?? undefined} />
                </Grid>
                <Grid xs={12} paddingTop={'1em'}>
                    {distributeChain && <TaskLogListComponent distributeChain={distributeChain} />}
                </Grid>
            </Grid>
        </>
    );
}

export default DistributeChainOverview;