import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography, Grid } from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import {WalletInput, Wallet} from "../../types/Wallet";
import {Company} from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import {useApi} from "../../context/ApiProvider";

const UpsertWallet: React.FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState('');
    const [rawMnemonic, setRawMnemonic] = useState('');
    const [company, setCompany] = useState<Company|undefined>(undefined);

    useEffect(() => {
        if (id) {
            api?.get(`/wallets/${id}`)
                .then((response) => {
                    if (response.ok && response.body) {
                        const data = response.body;
                        setName(data.name);
                        setRawMnemonic('');
                        setCompany(data.company);
                        return;
                    }

                    throw new Error('Failed to fetch wallet data!')
                })
                .catch((error: AxiosError) => {
                    console.error('Error:', error);
                    enqueueSnackbar(`Failed to load wallet. Error: ${error}`, {variant: 'error'})
                });
        }
    }, [id, api]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!company) {
            enqueueSnackbar('Company is required', {variant: 'error'})
            return
        }

        const wallet: WalletInput = {
            name: name,
            raw_mnemonic: rawMnemonic,
            company_id: company.id
        };

        let data: Wallet|undefined;
        if (id) {
            data = (await api?.put(`/wallets/${id}`, wallet))?.body
        } else {
            data = (await api?.post('/wallets', wallet))?.body
        }
        console.log(data)

        if (data !== undefined) {
            enqueueSnackbar('Successfully created/updated wallet!', {variant: 'success'})
            navigate('/wallets/' + (data?.id ?? id ?? ''))
        } else {
            enqueueSnackbar('Failed to create/update wallet!', {variant: 'error'})
        }
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {id ? 'Update' : 'Create'} Wallet
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CompanyAutocomplete company={company ?? null} setCompany={setCompany} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth
                                       label="Mnemonic"
                                       value={rawMnemonic} onChange={e => setRawMnemonic(e.target.value)}
                                       required={!id} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                {id ? 'Update' : 'Create'} Wallet
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default UpsertWallet;