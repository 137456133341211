import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {TextField, Button, Container, Box, Typography, Grid, FormControlLabel, Checkbox} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {CompanyInput} from "../../types/Company";
import {useApi} from "../../context/ApiProvider";

type RouteParams = {
    [key: number]: string | undefined;
};

const UpsertCompany: React.FC = () => {
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id?: number };
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        if (id) {
            api?.get(`/companies/${id}`)
                .then(response => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch!')
                    }

                    setName(response.body.name)
                    setIsActive(response.body.is_active)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [id, api]);

    useEffect(() => {
        fetchData();
    }, [fetchData, id]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const company: CompanyInput = {
            name: name,
            is_active: isActive,
        };
        let response;
        if (id) {
            response = await api?.put(`/companies/${id}`, company)
        } else {
            response = await api?.post(`/companies`, company)
        }

        if (response && response.ok) {
            enqueueSnackbar('Successfully updated!', {variant: 'success'})
            navigate('/companies/' + (response.body.id ?? id ?? ''))
        } else {
            const error = response?.body?.message || response?.body?.error || response?.body || response;
            enqueueSnackbar(`Failed! Error: ${error}`, {variant: 'error'})
        }
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    { id ? 'Update' : 'Create' } Company
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={isActive} onChange={e => setIsActive(e.target.checked)} />}
                                label="Is Active"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                {id ? 'Update' : 'Create'} Company
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default UpsertCompany;