import React, {useState, useEffect, useCallback} from 'react';
import {
    Button,
    LinearProgress, Typography, Grid, Card, CardContent, CardActions, Box
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import {Wallet} from "../../types/Wallet";
import ChainsAutocomplete from "../Chains";
import {useApi} from "../../context/ApiProvider";

type RouteParams = {
    [key: number]: string;
};

const WalletOverview: React.FC = () => {
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [wallet, setWallet] = useState<Wallet | null>(null);
    const [generatedAddress, setGeneratedAddress] = useState<string | null>(null);
    const [chain, setChain] = useState<string | undefined>(undefined);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setLoading(true)
        api?.get(`/wallets/${id}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch configuration data!')
                }

                setWallet(response.body)
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch configuration data!', {variant: 'error'});
                setWallet(null)
            })
            .finally(() => setLoading(false))
        ;
    }, [id, api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false
        }
    }, [fetchData]);

    const generateChain = (chain: string) => {
        api?.get(`/wallets/${id}/address/${chain}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to generate address!')
                }

                setGeneratedAddress(response.body.address);
                enqueueSnackbar(`Address generated for ${chain}: ${response.body.address}`, {variant: 'success'});
                setChain(undefined);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to generate address!' + error.toString(), {variant: 'error'});
                setGeneratedAddress(null);
                setChain(undefined);
            })
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>Configuration Overview</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {isLoading ? <LinearProgress /> : (wallet && (
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Name: {wallet.name}</Typography>
                                <Typography variant="body1">Created At: {wallet.created_at?.toLocaleString()}</Typography>
                                <Typography variant="body1">Updated At: {wallet.updated_at?.toLocaleString()}</Typography>
                            </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        href={`/wallets/${wallet.id}/edit`}
                                        color="warning">
                                        Edit
                                    </Button>
                            </CardActions>
                        </Card>
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>Generate Address</Typography>
                    <ChainsAutocomplete chain={chain} setChain={generateChain} />
                    {generatedAddress && (
                        <Typography variant="body1">Generated Address: {generatedAddress}</Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default WalletOverview;