import React, { useState, useEffect, useCallback } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Container, Box, Typography, Grid } from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import {DistributeChain, DistributeChainInput} from "../../types/DistributeChain";
import ChainsAutocomplete from "../Chains";
import ValidatorsAutocomplete from "../Validators";
import {ChainDirectory, CosmosDirectory} from "@tedcryptoorg/cosmos-directory";
import {enqueueSnackbar} from "notistack";
import axios, {AxiosError} from "axios";
import {Wallet} from "../../types/Wallet";
import WalletAutocomplete from "../Wallet/WalletAutocomplete";
import {Company} from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import {useApi} from "../../context/ApiProvider";

type RouteParams = {
    [key: number]: string | undefined;
};

const cosmosDirectory = new CosmosDirectory();
const chainDirectory = new ChainDirectory();

const UpsertDistributeChain: React.FC = () => {
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id?: number };
    const [name, setName] = useState('');
    const [chain, setChain] = useState('');
    const [fee, setFee] = useState('');
    const [gasAdjustment, setGasAdjustment] = useState(1.3);
    const [restUrl, setRestUrl] = useState('');
    const [rpcUrl, setRpcUrl] = useState('');
    const [defaultDenom, setDefaultDenom] = useState('');
    const [validatorAddress, setValidatorAddress] = useState('');
    const [claimGreaterThan, setClaimGreaterThan] = useState('');
    const [wallet, setWallet] = useState<Wallet|null>(null);
    const [company, setCompany] = useState<Company|null>(null);
    const [isActive, setIsActive] = useState(true);
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        if (id) {
            api?.get(`/distribute-chains/${id}`)
                .then((response) => {
                    if (response.ok && response.body) {
                        const data = response.body
                        setName(data.name);
                        setChain(data.chain);
                        setFee(data.fee);
                        setGasAdjustment(data.gas_adjustment);
                        setRestUrl(data.rest_url);
                        setRpcUrl(data.rpc_url);
                        setDefaultDenom(data.default_denom);
                        setValidatorAddress(data.validator_address);
                        setClaimGreaterThan(data.claim_greater_than);
                        setIsActive(data.is_active);
                        setWallet(data.wallet);
                        setCompany(data.company);
                        return
                    }

                    throw new Error('Failed to fetch distribute chain data!')
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to fetch distribute chain data!', {variant: 'error'})
                })
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [fetchData, id]);

    const handleChainChange = async (chain: string) => {
        setChain(chain);
        const chainData = await chainDirectory.getChainData(chain);
        if (chainData.chain.fees.fee_tokens && chainData.chain.fees.fee_tokens.length > 0) {
            setFee(chainData.chain.fees.fee_tokens[0].average_gas_price.toString());
        }
        setRpcUrl(cosmosDirectory.rpcUrl(chain));
        setRestUrl(cosmosDirectory.restUrl(chain));
        setDefaultDenom(chainData.chain.denom);
        setClaimGreaterThan(Math.pow(10, chainData.chain.decimals).toString())
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!wallet) {
            enqueueSnackbar('Please select a wallet!', {variant: 'error'});
            return;
        }
        if (!company) {
            enqueueSnackbar('Please select a company!', {variant: 'error'});
            return;
        }

        const distributeChain: DistributeChainInput = {
            name: name,
            chain: chain,
            fee: fee,
            gas_adjustment: gasAdjustment,
            rest_url: restUrl,
            rpc_url: rpcUrl,
            default_denom: defaultDenom,
            validator_address: validatorAddress,
            claim_greater_than: claimGreaterThan,
            wallet_id: wallet.id,
            company_id: company.id,
            is_active: isActive
        };

        let response;
        if (id) {
            response = api?.put(`/distribute-chains/${id}`, distributeChain)
        } else {
            response = api?.post(`/distribute-chains`, distributeChain)
        }
        if (response === undefined) {
            enqueueSnackbar('Failed to update!', {variant: 'error'});
            return
        }

        response
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update!')
                }

                enqueueSnackbar('Successfully updated distribute chain!', {variant: 'success'})
                navigate('/distribute-chains/' + (response.body?.id ?? id ?? ''))
            })
            .catch((error: any) => {
                console.error('Error:', error);

                const data: any = error.response?.data;
                if (data) {
                    const errorMessage = data.message || data.error || data;
                    enqueueSnackbar(`Failed! Error: ${errorMessage}`, {variant: 'error'})

                    return
                }

                enqueueSnackbar(`Failed! Error: ${error}`, {variant: 'error'})
            });
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {id ? 'Update' : 'Add'} Distribute Chain
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ChainsAutocomplete setChain={handleChainChange} chain={chain} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Fee" value={fee} onChange={e => setFee(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Gas Adjustment" type="number" value={gasAdjustment} onChange={e => setGasAdjustment(Number(e.target.value))} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Rest URL" value={restUrl} onChange={e => setRestUrl(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="RPC URL" value={rpcUrl} onChange={e => setRpcUrl(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Default Denom" value={defaultDenom} onChange={e => setDefaultDenom(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ValidatorsAutocomplete address={validatorAddress} chain={chain} setValidatorAddress={setValidatorAddress} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Claim Greater Than" value={claimGreaterThan} onChange={e => setClaimGreaterThan(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WalletAutocomplete wallet={wallet} setWallet={setWallet} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CompanyAutocomplete company={company} setCompany={setCompany} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={isActive} onChange={e => setIsActive(e.target.checked)} />}
                                label="Is Active"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                {id ? 'Update' : 'Add'} Distribute Chain
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default UpsertDistributeChain;