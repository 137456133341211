import React, {useEffect, useState} from "react";
import { Autocomplete, TextField } from '@mui/material';
import {Wallet} from "../../types/Wallet";
import {useApi} from "../../context/ApiProvider";

interface ChainsAutocompleteProps {
    wallet: Wallet|null,
    setWallet: (wallet: Wallet) => void
}

const WalletAutocomplete: React.FC<ChainsAutocompleteProps> = (
    {
        wallet,
        setWallet
    }) => {
    const api = useApi();
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        setWallets([]);
        api?.get(`/wallets`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch wallets')
                }

                setWallets(response.body.results);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }, [wallet]);

    return (
        <>
            <Autocomplete
                options={wallets}
                loading={loading}
                selectOnFocus={true}
                clearOnBlur={true}
                handleHomeEndKeys={true}
                getOptionLabel={(option) => option.name}
                value={wallet
                    ? wallets.find((walletDatum: Wallet) => walletDatum.id === wallet.id)
                    : null}
                onChange={(event, newValue) => {
                    if (newValue && newValue.id !== wallet?.id) {
                        const selectedWallet = wallets.find((walletDatum: Wallet) => walletDatum.id === newValue.id);
                        if (selectedWallet) {
                            setWallet(selectedWallet);
                        }
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Wallet" variant="outlined" required />}
            />
        </>
    );
}

export default WalletAutocomplete;