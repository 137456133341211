import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress, Stack, Grid, Typography
} from '@mui/material';
import BooleanIcon from "../Shared/BooleanIcon";
import DistributeChainLink from "./DistributeChainLink";
import {enqueueSnackbar} from "notistack";
import {DistributeChain} from "../../types/DistributeChain";
import CompanyLink from "../Company/CompanyLink";
import AdminButton from "../Shared/AdminButton";
import {useApi} from "../../context/ApiProvider";

const DistributeChainComponent: React.FC = () => {
    const api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [distributeChainsData, setDistributeChainsData] = useState([]);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true)
        api?.get('/distribute-chains')
            .then((response) => {
                if (response.ok && response.body) {
                    setDistributeChainsData(response.body.results);
                    return
                }

                throw new Error('Failed to fetch distribute chain data!')
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch distribute chain data!', {variant: 'error'})
                setDistributeChainsData([])
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = (distributeChain: DistributeChain) => () => {
        api?.delete(`/distribute-chains/${distributeChain.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete distribute chain')
                }

                enqueueSnackbar('Distribute chain deleted!', {variant: 'success'})
                fetchData()
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to delete distribute chain!', {variant: 'error'})
            });
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4">Distribute Chain</Typography>
            </Grid>
            <Grid item xs={12}>
                <AdminButton
                    variant={"contained"}
                    color={"success"}
                    to={"/distribute-chains/add"}
                    label={"Add Distribute chain"}
                />
            </Grid>
            <Grid item xs={12}>
                {isLoading ? <LinearProgress /> : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Chain</TableCell>
                                    <TableCell>Wallet</TableCell>
                                    <TableCell>Validator</TableCell>
                                    <TableCell>Is Active</TableCell>
                                    <TableCell>Last claimed at</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distributeChainsData.map((row: any) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>
                                            <DistributeChainLink distributeChain={row} />
                                        </TableCell>
                                        <TableCell>{row.chain}</TableCell>
                                        <TableCell>{row.wallet?.name}</TableCell>
                                        <TableCell>{row.validator_address}</TableCell>
                                        <TableCell>
                                            <BooleanIcon value={row.is_active} />
                                        </TableCell>
                                        <TableCell>{row.last_claimed_at}</TableCell>
                                        <TableCell>
                                            <CompanyLink company={row.company} />
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <AdminButton
                                                    variant={"contained"}
                                                    color={"warning"}
                                                    href={`/distribute-chains/${row.id}/edit`}
                                                    label={"Edit"}
                                                />
                                                <AdminButton
                                                    variant={"contained"}
                                                    color={"error"}
                                                    label={"Delete"}
                                                    onClick={handleDelete(row)}
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>)}
            </Grid>
        </Grid>
    );
}

export default DistributeChainComponent;