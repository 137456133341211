import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, Button, Collapse
} from '@mui/material';
import {Task} from "../../types/Task";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {TaskLog} from "../../types/TaskLog";
import {DistributeChain} from "../../types/DistributeChain";
import {useApi} from "../../context/ApiProvider";

interface TaskLogListComponentProps {
    distributeChain?: DistributeChain
    task?: Task
}

export const TaskLogListComponent: React.FC<TaskLogListComponentProps> = ({
    distributeChain,
    task
}) => {
    const api = useApi()
    const [taskLogs, setTaskLogs] = useState<TaskLog[]>([])
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const url = task
            ? `/tasks/${task.id}/logs`
            : (distributeChain ? `/distribute-chains/${distributeChain.id}/task-logs` : undefined)
        if (!url) {
            throw new Error('TaskLogListComponent: distributeChain or task must be provided')
        }

        api?.get(url)
            .then((response) => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch!')
                }

                setTaskLogs(response.body.results)
            })
            .catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar('Failed to fetch Task Logs!', {variant: 'error'})
            })
    }, [distributeChain, task, api]);

    return (
        <div>
            <h2>Task Logs</h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Task</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Transaction Hash</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taskLogs && taskLogs.map((row: TaskLog) => (
                            <>
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{`${row.task.name} (${row.task.id})`}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.tx_hash}</TableCell>
                                    <TableCell>{row.created_at}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => setOpen(!open)} variant="contained">
                                            {open ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <code>{JSON.stringify(row.extra_data, null, 2)}</code>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}