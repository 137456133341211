import NotificationChannelsList from "./NotificationChannelsList";

const NotificationChannelsComponent: React.FC = () => {
    return (
        <>
            <NotificationChannelsList />
        </>
    )
}

export default NotificationChannelsComponent;
