import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Stack,
    TextField
} from '@mui/material';
import Switch from '@mui/material/Switch';
import {NotificationChannel, NotificationChannelInput} from "../../types/NotificationChannel";
import {NotificationChannelTypeEnum} from "./NotificationChannelType";
import {enqueueSnackbar} from "notistack";
import Telegram from "./types/Telegram";
import {TelegramBotConfiguration} from "./types/TelegramBotConfiguration";
import {Company} from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import {useApi} from "../../context/ApiProvider";

interface UpsertNotificationChannelModalProps {
    open: boolean,
    fetchData: () => void;
    notificationChannel: NotificationChannel|null;
    handleClose: any;
}
const UpsertNotificationChannelModal: React.FC<UpsertNotificationChannelModalProps> = (
    {
        open,
        fetchData,
        notificationChannel,
        handleClose
    }) => {
    const api = useApi();
    const [id, setId] = useState(notificationChannel ? notificationChannel.id : undefined);
    const [name, setName] = useState(notificationChannel ? notificationChannel.name : '');
    const [type, setType] = useState<NotificationChannelTypeEnum|null>(notificationChannel ? notificationChannel.type : null);
    const [configurationObject, setConfigurationObject] = useState(notificationChannel ? notificationChannel.configuration_object : {});
    const [isEnabled, setIsEnabled] = useState(notificationChannel ? notificationChannel.is_enabled : true);
    const [company, setCompany] = useState<Company|undefined>(notificationChannel ? notificationChannel.company : undefined);

    useEffect(() => {
        setId(notificationChannel ? notificationChannel.id : undefined)
        setName(notificationChannel ? notificationChannel.name : '');
        setType(notificationChannel ? notificationChannel.type : null);
        setConfigurationObject(notificationChannel ? JSON.parse(notificationChannel.configuration_object) : {});
        setIsEnabled(notificationChannel ? notificationChannel.is_enabled : true);
        setCompany(notificationChannel ? notificationChannel.company : undefined);
    }, [notificationChannel]);

    const customHandleClose = () => {
        setName('')
        setType(null)
        setConfigurationObject({})
        setIsEnabled(true);
        setCompany(undefined);

        handleClose();
    }

    const validate = () => {
        if (!name) {
            enqueueSnackbar('Please enter a name!', {variant: 'error'});
            return false;
        }
        if (type === null) {
            enqueueSnackbar('Please select a type!', {variant: 'error'});
            return false;
        }
        if (configurationObject === null) {
            enqueueSnackbar('Please enter a configuration object!', {variant: 'error'});
            return false;
        }
        if (company === undefined) {
            enqueueSnackbar('Please select a company!', {variant: 'error'});
            return false;
        }

        return true;
    }

    const handleTest = (event: React.FormEvent) => {
        event.preventDefault();
        if (!validate()) {
            return;
        }

        const notificationChannelInput: NotificationChannelInput = {
            name: name,
            type: type as NotificationChannelTypeEnum,
            company_id: company?.id ?? 0,
            configuration_object: JSON.stringify(configurationObject ?? {}),
            is_enabled: isEnabled,
        };

        api?.post(`/notification-channels/test`, notificationChannelInput)
            .then(response => {
                if (!response.ok) {
                    enqueueSnackbar('Failed to test notification channel!', {variant:'error'});
                    return;
                }

                enqueueSnackbar('Notification channel tested successfully!', {variant:'success'});
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to test notification channel!', {variant:'error'});
            });
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (!validate()) {
            return;
        }

        const notificationChannelInput: NotificationChannelInput = {
            name: name,
            type: type as NotificationChannelTypeEnum,
            company_id: (company as Company).id ,
            configuration_object: JSON.stringify(configurationObject ?? {}),
            is_enabled: isEnabled,
            id: id,
        };

        let response;
        if (notificationChannel) {
            response = api?.put(`/notification-channels/${notificationChannel.id}`, notificationChannelInput)
        } else {
            response = api?.post(`/notification-channels`, notificationChannelInput)
        }
        if (response === undefined) {
            enqueueSnackbar('Failed to add notification channel!', {variant:'error'});
            return;
        }

       response
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to add notification channel!')
                }

                enqueueSnackbar('Notification channel added successfully!', {variant:'success'});
                fetchData();
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to add notification channel!', {variant:'error'});
            });

        customHandleClose();
    };

    return (
        <>
            <Dialog open={open} onClose={customHandleClose}>
                <DialogTitle>{id ? 'Edit' : 'Add'} Notification Channel</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={name}
                                required
                                autoComplete={'off'}
                                onChange={e => setName(e.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="type-select-label">Type*</InputLabel>
                                <Select
                                    labelId="type-select-label"
                                    id="demo-simple-select"
                                    value={type ?? ''}
                                    required
                                    label="Type*"
                                    onChange={(event: SelectChangeEvent) => setType(event.target.value as NotificationChannelTypeEnum)}
                                >
                                    {Object.values(NotificationChannelTypeEnum).map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                label="Is Enabled"
                                control={
                                    <Switch
                                        checked={isEnabled}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsEnabled(event.target.checked)}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                            />
                            <CompanyAutocomplete company={company ?? null} setCompany={setCompany} />
                            {type === NotificationChannelTypeEnum.TELEGRAM && <Telegram configurationObject={configurationObject as TelegramBotConfiguration} setConfigurationObject={setConfigurationObject} />}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={customHandleClose} color="error">Cancel</Button>
                        <Button variant="outlined" onClick={handleTest} color="warning">Test</Button>
                        <Button variant="contained" type="submit">{notificationChannel ? 'Edit' : 'Add'}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default UpsertNotificationChannelModal;
