import React, {useEffect, useState} from "react";
import { Autocomplete, TextField } from '@mui/material';
import {ValidatorChain, ValidatorsDirectory} from "@tedcryptoorg/cosmos-directory";

const validatorsDirectory = new ValidatorsDirectory();

interface ChainsAutocompleteProps {
    address: string,
    chain: string,
    setValidatorAddress: (address: string) => void
}

const ValidatorsAutocomplete: React.FC<ChainsAutocompleteProps> = (
    {
        address,
        chain,
        setValidatorAddress
    }) => {
    const [validators, setValidators] = useState<ValidatorChain[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        if (chain) {
            validatorsDirectory.getValidators(chain)
                .then((response) => {
                    setValidators(response.validators)
                    setLoading(false)
                    console.log('Validators', response.validators)
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
        }
    }, [chain]);

    return (
        <>
            <Autocomplete
                options={validators}
                loading={loading}
                selectOnFocus={true}
                clearOnBlur={true}
                disabled={!chain}
                handleHomeEndKeys={true}
                getOptionLabel={(option) => option.moniker}
                value={address
                    ? validators.find((validator: ValidatorChain) => validator.address === address)
                    : null}
                onChange={(event, newValue) => {
                    setValidatorAddress(newValue ? newValue.address : '');
                }}
                renderInput={(params) => <TextField {...params} label="Validator" variant="outlined" required />}
            />
        </>
    );
}

export default ValidatorsAutocomplete;